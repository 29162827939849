import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-prestige-classes"></a><h2>Epic Prestige Classes
    </h2>
    <a id="high-proselytizer"></a><h3>HIGH PROSELYTIZER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>Hit Die:</span> d8.
    <a id="high-proselytizer-requirements"></a><h6>Requirements</h6>
To qualify to become a high proselytizer, a character must
fulfill all the following criteria.
    <p><span style={{
        "fontWeight": "bold"
      }}>Skills:</span> <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
12 ranks and either <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (religion) 24 ranks or
Knowledge (nature) 24 ranks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Feats:</span> <a href="featsAll.html#leadership" style={{
        "color": "rgb(87, 158, 182)"
      }}>Leadership</a>.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Epic Feats:</span> <a href="epicFeats.html#epic-leadership" style={{
        "color": "rgb(87, 158, 182)"
      }}>Epic
Leadership</a>. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells:</span> Ability to
cast 5th-level divine spells. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span> Must have a
patron deity. </p>
    <p style={{
      "fontWeight": "bold"
    }}>Class Skills</p>
    <p>The high proselytizer&#8217;s class skills (and the key ability for
each skill) are <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> (Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="skillsAll.html#heal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Heal</a>
(Wis), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (arcana) (Int),
Knowledge (religion) (Int), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a> (Wis), <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense
Motive</a> (Wis), and <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a> (Int). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Skill Points at Each Level:</span>
2 + Int modifier. </p>
&nbsp;
    <a id="table-the-high-proselytizer"></a><span style={{
      "fontWeight": "bold"
    }}>Table: The High Proselytizer </span>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Level</th>
          <th style={{
            "width": "350px"
          }}>Special
          </th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Spells per Day</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>1st
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Proselytize</span> 1/day
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
        </tr>
        <tr>
          <td>2nd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Heal</span> 1/day</td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of divine
spellcasting class </td>
        </tr>
        <tr className="odd-row">
          <td>3rd
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Proselytize (deific touch)</span> 1/day</td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
        </tr>
        <tr>
          <td>4th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of divine
spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>5th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Proselytize (deific word)</span> 1/day</td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
        </tr>
        <tr>
          <td>6th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Heal</span> 2/day</td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of divine
spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>7th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Proselytize (deific face)</span> 1/day</td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
        </tr>
        <tr>
          <td>8th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}>Bonus feat</td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of divine
spellcasting class</td>
        </tr>
        <tr className="odd-row">
          <td>9th
          </td>
          <td rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Proselytize (deific aura)</span> 1/day</td>
          <td style={{
            "verticalAlign": "top"
          }}>
          </td>
        </tr>
        <tr>
          <td className="last-row">10th
          </td>
          <td className="last-row" rowSpan="1" colSpan="1" style={{
            "width": "350px"
          }}><span style={{
              "fontStyle": "italic"
            }}>Heal</span>
3/day</td>
          <td style={{
            "verticalAlign": "top"
          }}>+1 level of divine
spellcasting class</td>
        </tr>
      </tbody>
    </table>
    <a id="high-proselytizer-class-features"></a><h6>Class Features</h6>
The following are class features of the high proselytizer prestige
class. 
    <p><span style={{
        "fontWeight": "bold"
      }}>Weapon and Armor Proficiency:</span>
High proselytizers are proficient with all simple weapons, all armor,
and all shields. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Spells per Day:</span> At
every other high proselytizer level, the character gains new spells per
day as if he or she had also gained a level in a divine spellcasting
class to which he or she belonged before adding the prestige class
level. If already an epic spellcaster, the character gains only the
benefit noted under the Spells entry for that epic class. He or she
does not, however, gain any other benefit a character of that class
would have gained. If the character had more than one divine
spellcasting class before becoming a high proselytizer, the player must
decide to which class to add each high proselytizer level for the
purpose of determining spells per day. </p>
    <a id="high-proselytizer-proselytize"></a><p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Proselytize
(Sp):</span> At 1st level, the high proselytizer gains the ability to
preach the word of his or her deity to large crowds with great effect.
He or she may speak in such a manner once per day, plus one additional
time per day per ten class levels thereafter. </p>
    <p>Proselytize has three effects. First, any time the high
proselytizer is speaking in this manner, he or she is treated as though
affected by a sanctuary spell. Second, his or her voice can be heard
clearly by anyone within a radius of 100 feet plus 50 feet per class
level, regardless of background noise, and his or her speech can be
understood as though the audience were affected by a comprehend
languages spell. Finally, everyone in the range of the high
proselytizer&#8217;s voice immediately has a chance of becoming enraptured:
    </p>
    <a id="table-proselytize-effect"></a><table style={{
      "width": "92%"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "textAlign": "left",
            "width": "200px"
          }}>Type of Listener
          </th>
          <th style={{
            "textAlign": "left"
          }}>Effect
          </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "textAlign": "left",
            "width": "200px"
          }}>Followers of
the same deity</td>
          <td style={{
            "textAlign": "left"
          }}>Automatic if 10 or fewer HD,
otherwise Will save (DC 20 + 1/2 high proselytizer&#8217;s class level + Wis
modifier)</td>
        </tr>
        <tr>
          <td style={{
            "textAlign": "left",
            "width": "200px"
          }}>Others of the
same alignment</td>
          <td style={{
            "textAlign": "left"
          }}>Automatic if 5 or fewer HD,
otherwise Will save (as above)</td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "textAlign": "left",
            "width": "200px"
          }}>All others</td>
          <td style={{
            "textAlign": "left"
          }}>Will save (as above)</td>
        </tr>
      </tbody>
    </table>
    <p> </p>
    <p>Enraptured audience members act as though affected by a symbol
of persuasion, changing alignment as appropriate and otherwise
functioning according to the charm person spell. The high proselytizer
can inspire the crowd to take any of a number of actions, depending on
his or her alignment. Any suicidal suggestion grants audience members a
new saving throw to break the rapture (with the exception of low-level
followers of the same deity, who never got a save in the first place).
This rapture lasts for 10 minutes plus an additional 5 minutes per high
proselytizer level. </p>
    <p>At 3rd level, the high proselytizer�s proselytize ability
includes deific touch once per day, plus one additional
time per day per ten levels thereafter (13th, 23rd, 33rd,
and so on). During her speech, she can move among the
enraptured, shaking hands, caressing brows, and otherwise making contact with audience members.
Anyone so touched is healed of 1d4 points of damage
and cured of any natural disease or poison. Up to six
individuals per round can be so affected. An audience
member can benefit from deific touch only once per
proselytize session. The high proselytizer can use deific
touch as long as her proselytize ability lasts.</p>
    <p>At 5th level, the proselytize ability includes deific word
once per day, plus one additional time per day per ten levels
thereafter. The words spoken by the high proselytizer can, if he or she
chooses, function as a triple-strength sound burst spell (3d8 points of
sonic damage and a Will save to avoid being stunned for 3 rounds) to
all who are not enraptured, as the spell cast by a 20th-level cleric.
The deific word can occur at any point during his or her proselytize
speech. </p>
    <p>At 7th level, the proselytize ability includes deific face
once per day, plus one additional time per day per ten levels
thereafter. When the high proselytizer speaks, he or she can cause a
blinding burst to shine from his or her face. Deific face functions
against all in the audience who are not enraptured as the sunburst
spell cast by a 20th-level cleric. The deific face can occur at any
point during his or her speech. </p>
    <p>At 9th level, the proselytize ability includes deific aura
once per day, plus one additional time per day per ten levels
thereafter. When the high proselytizer speaks, he or she can cause a
rolling wave of deific power to spring from his or her body that
functions as either a blasphemy, dictum, holy word, or word of chaos
spell (as appropriate for his or her alignment), affecting only those
in the audience who have resisted becoming enraptured. Deific aura
otherwise functions as the relevant spell cast by a 20th-level cleric.
The deific aura can occur at any point during his or her speech. </p>
    <p><span style={{
        "fontWeight": "bold",
        "fontStyle": "italic"
      }}>Heal (Sp):</span>
At 2nd level, the high proselytizer can use heal on his or herself or
another creature once per day, plus one additional time per day per
four levels thereafter. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Bonus Feats:</span> The high
proselytizer gets a bonus feat at 4th level and an additional bonus
feat every four levels thereafter. These bonus feats must be chosen
from the following list: Armor Skin, Automatic Quicken Spell, Automatic
Silent Spell, Automatic Still Spell, Bonus Domain, Enhance Spell, Epic
Reputation, Epic Spell Focus, Epic Spell Penetration, Epic
Spellcasting, Epic Will, Extended Life Span, Great Charisma, Great
Wisdom, Ignore Material Components, Improved Alignment-Based Casting,
Improved Combat Casting, Improved Heighten Spell, Improved Metamagic,
Improved Spell Capacity, Intensify Spell, Legendary Commander,
Multispell, Negative Energy Burst, Per-manent Emanation, Planar
Turning, Polyglot, Positive Energy Aura, Spectral Strike, Spell
Stowaway, Spell Opportunity, Spontaneous Domain Access, Spontaneous
Spell, Tenacious Magic, Undead Mastery, Zone of Animation.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      